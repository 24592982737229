import React from 'react';
import { FormattedMessage } from 'react-intl';

export default class Slogan extends React.Component {

    render() {
        return (
            <div id="content-container" className="container-side-item">
                <p className="text-large">
                    <FormattedMessage id="slogan.title" />
                </p>
                <p className="text-small">
                    <FormattedMessage id="slogan.description" />
                </p>
            </div>
        )
    }
}