import React from 'react'

import './BusinessInfo.css'
import { FormattedMessage } from 'react-intl'

export default class BusinessInformation extends React.Component {

    render() {
        return (
            <div id="content-container" className="container">
                <div>
                    <p className="text-small">
                        <b><FormattedMessage id="business-info.kvk.title" /></b>
                    </p>
                    <p className="text-small">65368665</p>
                </div>

                <div>
                    <p className="text-small">
                        <b><FormattedMessage id="business-info.vat.title" /></b>
                    </p>
                    <p className="text-small">NL001104221B25</p>
                </div>
            </div>
        )
    }
}