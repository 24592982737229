import React from 'react'
import { BrowserRouter, Switch, Route } from "react-router-dom";

import './App.css'
import Background from './components/Background'

import logo from './res/logo/logo_wordmark.png'

import Slogan from './components/Slogan'
import ContactUs from './components/ContactUs'
import OurGames from './components/ourgames/OurGames'
import BusinessInformation from './components/businessinfo/BusinessInfo'
import IntlProviderWrapper from './components/language/IntlContext';

export default class App extends React.Component {

  render() {
    return (
      <IntlProviderWrapper>

        <BrowserRouter>
          <Background />

          <div id="container">
            <img id="logo" alt="logo" src={logo} />

            <div className="container-side">
              <Slogan />
              <ContactUs />
            </div>

            <Switch>
              <Route path="/games" exact>
                <OurGames />
              </Route>

              <Route path="/" />
            </Switch>

            <BusinessInformation />
          </div>
        </BrowserRouter>

      </IntlProviderWrapper>
    )
  }
}