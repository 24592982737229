import React from 'react';

import './Socials.css'

import github from '../../res/social/github.png'
import linkedin from '../../res/social/linkedin.png'
import youtube from '../../res/social/youtube.png'
import facebook from '../../res/social/facebook.png'
import twitter from '../../res/social/twitter.png'

export default class Socials extends React.Component {

    render() {
        return (
            <div id="socials-container">
                <SocialsItem alt="github" img={github} href="https://github.com/SnepicaStudios" />
                <SocialsItem alt="linkedin" img={linkedin} href="https://linkedin.com/company/snepica-studios" />
                <SocialsItem alt="youtube" img={youtube} href="https://youtube.com/c/SnepicaStudios" />
                <SocialsItem alt="facebook" img={facebook} href="https://facebook.com/SnepicaStudios" />
                <SocialsItem alt="twitter" img={twitter} href="https://twitter.com/SnepicaStudios" />
            </div>
        )
    }
}

type SocialItemProps = {
    alt: string
    img: string
    href: string
}

class SocialsItem extends React.Component<SocialItemProps, {}> {

    render() {
        return (
            <a href={this.props.href} target="_blank" rel="noopener noreferrer">
                <img alt={this.props.alt} src={this.props.img} className="socials-item" />
            </a>
        )
    }
}