import React from 'react'
import Socials from './socials/Socials'
import { FormattedMessage } from 'react-intl'

export default class ContactUs extends React.Component {

    render() {
        return (
            <div id="content-container" className="container-side-item">
                <p className="text-large">
                    <FormattedMessage id="contact-us.title" />
                </p>
                <p className="text-small"><a href="mailto:info@snepicastudios.com">info@snepicastudios.com</a></p>

                <br />
                <Socials />
            </div>
        )
    }
}