import React from 'react'

type BackgroundState = {
    O_WIDTH: number
    O_HEIGHT: number
    O_SPEED: number
    WIDTH: number
    HEIGHT: number
    MID_X: number
    MID_Y: number

    size: number
    maxDistance: number
    colors: Array<Color>
    points: Array<Point>
}

export default class Background extends React.Component<{}, BackgroundState> {

    private canvasContext: CanvasRenderingContext2D | null = null

    constructor(props: any) {
        super(props)

        const width = document.documentElement.clientWidth
        const height = document.documentElement.clientHeight
        const size = 1000

        this.state = {
            O_WIDTH: 1920,
            O_HEIGHT: 1080,
            O_SPEED: 0.4,
            WIDTH: width,
            HEIGHT: height,
            MID_X: width / 2,
            MID_Y: height / 2,

            size: size,
            maxDistance: Math.sqrt(Math.pow(size, 2) + Math.pow(size, 2)),
            colors: [
                { red: 255, green: 117, blue: 255 },
                { red: 129, green: 255, blue: 196 },
                { red: 121, green: 188, blue: 255 },
                { red: 255, green: 157, blue: 126 },
                { red: 120, green: 255, blue: 255 },
                { red: 255, green: 255, blue: 149 }
            ],
            points: []
        }
    }

    componentDidMount() {
        const canvas = document.querySelector("canvas")!
        this.canvasContext = canvas.getContext("2d")

        this.initWindow()
        window.addEventListener('resize', () => this.initWindow())

        const background = document.getElementById('fading-background')
        setTimeout(function () {
            background!.classList.add("fading-background-animation")
        }, 1000 / 4) // 0.25s

        this.init()
        this.animate()
    }

    private initWindow() {
        const width = document.documentElement.clientWidth
        const height = document.documentElement.clientHeight
        this.setState({
            WIDTH: width,
            HEIGHT: height,
            MID_X: width / 2,
            MID_Y: height / 2
        })
    }

    private init() {
        const seed = Math.E.toString().replace(".", "")
        const radius = 10

        let d = 0
        let x = 0.0
        let y = 0.0

        for (let yi = 0; yi < seed.length; yi++) {
            let ypc = parseInt(seed[yi])
            let yc = ypc * radius

            for (let xi = 0; xi < seed.length; xi++) {
                d++

                let xpc = parseInt(seed[xi])
                let xc = xpc * radius

                let dx = (xpc - 4) / 6 * (xi % 2 === 0 ? 1 : -1)
                let dy = (ypc - 4) / 6 * (yi % 2 === 0 ? 1 : -1)

                dx *= 2
                dy *= 2

                if (d % 3 === 0 && !(dx === 0.0 && dy === 0.0)) {
                    this.state.points.push(new Point(x + yc / 2, y + xc / 2, dx, dy))
                }
                x += xc
            }

            y += yc
            x = 0.0
        }

        for (let i = 0; i < this.state.points.length; i++) {
            let point = this.state.points[i]
            point.x -= this.state.size / 2
            point.y -= this.state.size / 2
            point.rx = point.x
            point.ry = point.y
        }

        // 71335

        for (let i = 0; i < 1400; i++) {
            this.updatePoints(true)
        }
    }

    private update() {
        this.updatePoints()
    }

    private updatePoints(initial?: boolean) {
        let speed = this.state.O_SPEED * this.state.O_HEIGHT / this.state.HEIGHT
        if (initial === true) speed = 1

        for (let i = 0; i < this.state.points.length; i++) {
            const point = this.state.points[i]
            point.rx -= point.dx * speed
            point.ry -= point.dy * speed

            const distance = Math.sqrt(Math.pow(point.rx, 2) + Math.pow(point.ry, 2))

            if (distance > this.state.maxDistance) {
                point.dx = -point.dx
                point.dy = -point.dy
                point.rx -= point.dx
                point.ry -= point.dy
            }

            point.x = point.rx
            point.y = point.ry
        }
    }

    private draw() {
        const scl = this.state.HEIGHT / this.state.O_HEIGHT / 2
        const radius = 10 * scl
        const pointDistance = 250

        const c = this.canvasContext!

        c.clearRect(0, 0, this.state.WIDTH, this.state.HEIGHT)

        // draw lines
        c.strokeStyle = "rgba(0, 255, 255, 0.5)"
        c.lineWidth = 2 * scl
        for (var i = 0; i < this.state.points.length; i++) {
            const point1 = this.state.points[i]
            let currentDistance = -1
            let connectedPoint1 = undefined
            let connectedPoint2 = undefined

            for (var j = 0; j < this.state.points.length; j++) {
                const point2 = this.state.points[j]
                if (point1 === point2) {
                    continue
                }

                const dx = point1.x - point2.x
                const dy = point1.y - point2.y
                const distance = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2))
                if ((currentDistance === -1 || distance < currentDistance) && distance <= pointDistance) {
                    currentDistance = distance
                    connectedPoint2 = connectedPoint1
                    connectedPoint1 = point2
                }
            }

            if (currentDistance < 0) {
                currentDistance = 0
            }

            const ci = i % this.state.colors.length
            const color = this.state.colors[ci]
            const d = 0.15 * 255

            let r = color.red
            let g = color.green
            let b = color.blue

            r += d
            g += d
            b += d

            if (r > 255) r = 255
            if (g > 255) g = 255
            if (b > 255) b = 255

            c.strokeStyle = `rgba(${r}, ${g}, ${b}, ${0.5 + 0.25 * (pointDistance - currentDistance) / pointDistance})`
            c.lineWidth = 3 * scl * (pointDistance - currentDistance) / (pointDistance / 2) * 1.5

            if (connectedPoint1 !== undefined) {
                c.beginPath()
                c.moveTo(this.state.MID_X + point1.x * scl, this.state.MID_Y + point1.y * scl)
                c.lineTo(this.state.MID_X + connectedPoint1.x * scl, this.state.MID_Y + connectedPoint1.y * scl)
                c.stroke()
            }

            if (connectedPoint2 !== undefined) {
                c.beginPath()
                c.moveTo(this.state.MID_X + point1.x * scl, this.state.MID_Y + point1.y * scl)
                c.lineTo(this.state.MID_X + connectedPoint2.x * scl, this.state.MID_Y + connectedPoint2.y * scl)
                c.stroke()
            }
        }

        // draw points
        c.fillStyle = "rgb(0, 0, 0)"
        for (let i = 0; i < this.state.points.length; i++) {
            const point = this.state.points[i]

            const distance = Math.sqrt(Math.pow(point.x, 2) + Math.pow(point.y, 2))

            const r = 1 + 3 * (this.state.maxDistance - distance) / this.state.maxDistance
            const rad = radius * r

            c.lineWidth = 2 * scl * r

            const ci = i % this.state.colors.length
            const color = this.state.colors[ci]

            c.strokeStyle = `rgb(${color.red}, ${color.green}, ${color.blue})`

            c.fillRect(this.state.MID_X + point.x * scl - rad / 2, this.state.MID_Y + point.y * scl - rad / 2, rad, rad)
            c.strokeRect(this.state.MID_X + point.x * scl - rad / 2, this.state.MID_Y + point.y * scl - rad / 2, rad, rad)
        }
    }

    private animationCall = () => this.animate()

    private animate() {
        requestAnimationFrame(this.animationCall)
        this.update()
        this.draw()
    }

    render() {
        return <div>
            <div id="fading-background"></div>
            <canvas
                width={this.state.WIDTH}
                height={this.state.HEIGHT}
            />
        </div>
    }
}

type Color = {
    red: number
    green: number
    blue: number
}

class Point {

    x: number
    y: number
    dx: number
    dy: number
    rx: number
    ry: number

    constructor(x: number, y: number, dx: number, dy: number) {
        this.rx = this.x = x
        this.ry = this.y = y
        this.dx = dx
        this.dy = dy
    }
}