import React from "react"

import './Language.css'

import { IntlProvider } from "react-intl"

import messages_nl from "../../translations/nl.json"
import messages_en from "../../translations/en.json"

type IntrlProviderState = {
    locale: string
    messages: {}
    switchLanguages: Array<{ locale: string, switch: () => void }>
}

export default class IntlProviderWrapper extends React.Component<{}, IntrlProviderState> {
    constructor(props: any) {
        super(props)

        const switchToEnglish = () => this.setState({ locale: "en", messages: messages_en })
        const switchToDutch = () => this.setState({ locale: "nl", messages: messages_nl })

        this.state = {
            locale: "en",
            messages: messages_en,
            switchLanguages: [
                { locale: "en", switch: switchToEnglish },
                { locale: "nl", switch: switchToDutch }
            ]
        }
    }

    render() {
        const { children } = this.props
        return (
            <IntlProvider
                key={this.state.locale}
                locale={this.state.locale}
                messages={this.state.messages}
                defaultLocale="en">

                {children}

                <div id="language-selector">
                    {this.state.switchLanguages.map((item, index) =>
                        <div
                            id="language-selector-item"
                            key={index}
                            onClick={item.switch}>

                            <p
                                key={item.locale}
                                className={"language-item " + (this.state.locale === item.locale ? "language-item-active" : "")}>
                                {item.locale}
                            </p>
                        </div>
                    )}
                </div>
            </IntlProvider>
        );
    }
}