import React from 'react'

import './OurGames.css'
import { FormattedMessage } from 'react-intl'

export default class OurGames extends React.Component {

    render() {
        return (
            <div className="container-side">
                <ItchIOWidget
                    title="Snek"
                    description_id="our-games.snek.description"
                    iframe_link="https://itch.io/embed/149712?border_width=0&amp;dark=true"
                />

                <ItchIOWidget
                    title="Dimensions"
                    description_id="our-games.dimensions.description"
                    iframe_link="https://itch.io/embed/27781?border_width=0&amp;dark=true"
                />

                <ItchIOWidget
                    title="Magneto"
                    description_id="our-games.magneto.description"
                    iframe_link="https://itch.io/embed/16379?border_width=0&amp;dark=true"
                />
            </div>
        )
    }
}

type ItchIOWidgetProps = {
    title: string
    description_id: string
    iframe_link: string
}

class ItchIOWidget extends React.Component<ItchIOWidgetProps, {}> {

    render() {
        return (
            <div id="content-container" className="container-side-item">
                <p className="text-large">{this.props.title}</p>
                <div className="game-content">
                    <p className="text-small">
                        <FormattedMessage id={this.props.description_id} />
                    </p>
                    <iframe title={this.props.title} className="game-widget" frameBorder="0" src={this.props.iframe_link} />
                </div>
            </div>
        )
    }
}